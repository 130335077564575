import '../../styles/pages/fall-winter-2023/a-titan-steps-into-a-galaxy-far-far-away.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
// import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2023-stories/more-stories"
import { Parallax, Background } from 'react-parallax';


import socialBanner from '../../images/fall-winter-2023/social-banners/a-titan-steps-into-a-galaxy-far-far-away.jpg';

import pershing from '../../images/fall-winter-2023/a-titan-steps-into-a-galaxy-far-far-away/pershing.png';
import bridge from '../../images/fall-winter-2023/a-titan-steps-into-a-galaxy-far-far-away/bridge.png';
import title from '../../images/fall-winter-2023/a-titan-steps-into-a-galaxy-far-far-away/title.svg';
import ctaBanner from '../../images/fall-winter-2023/a-titan-steps-into-a-galaxy-far-far-away/cta-banner.jpg'


import OmidAsPershing from '../../images/fall-winter-2023/a-titan-steps-into-a-galaxy-far-far-away/omid-as-pershing.jpg';
import OmidAbtahi from '../../images/fall-winter-2023/a-titan-steps-into-a-galaxy-far-far-away/Omid-Abtahi.jpg';
import PershingHologram from '../../images/fall-winter-2023/a-titan-steps-into-a-galaxy-far-far-away/pershing-hologram.jpg';
import PershingWithDroid from '../../images/fall-winter-2023/a-titan-steps-into-a-galaxy-far-far-away/pershing-with-droid.jpg';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'A Titan Steps Into a Galaxy Far, Far Away';
    var pageDesc = 'Cal State Fullerton alumnus Omid Abtahi plays Dr. Penn Pershing in Disney+’s ‘The Mandalorian.’';
    var slug = 'a-titan-steps-into-a-galaxy-far-far-away';

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2023">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`fall-winter-2023 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" id="mandalorian-hero">

          <div className="text">
            <h1 className="animated zoomIn delay-1s">
              <img src={title} alt={pageTitle} />
            </h1>
            <p className="sub-heading animated fadeInDown delay-2s">Cal State Fullerton alumnus Omid Abtahi plays<br />Dr. Penn Pershing in <strong>Disney+’s ‘The Mandalorian.’</strong></p>
            <span className="animated fadeInDown delay-2s byline">By Taylor Arrey</span>
          </div>

          <img src={pershing} id="pershing" alt="" />
          <img src={bridge} id="bridge" alt="" />
        
          <div className="hyperspace-scene">
            <div className="scene-wrap">
                <div className="wall wall-right"></div>
                <div className="wall wall-left"></div>   
                <div className="wall wall-top"></div>
                <div className="wall wall-bottom"></div> 
                <div className="wall wall-back"></div>    
            </div>
            <div className="scene-wrap">
                <div className="wall wall-right"></div>
                <div className="wall wall-left"></div>   
                <div className="wall wall-top"></div>
                <div className="wall wall-bottom"></div>   
                <div className="wall wall-back"></div>    
            </div>
          </div>  
        
        </div>
        <article>
          <div className='story-intro'>
            <div className='wrap small'>
              <p className='intro-text'>When Omid Abtahi got the call that he was going to be cast in “The Mandalorian,” he was sure he was dreaming. </p>

              <p>“It was an opportunity to work with Jon Favreau and be part of the ‘Star Wars’ universe. I was on cloud nine,” he says. </p>

              <blockquote className='align-left align-outside'>
                <p>“I’m very proud to be a Titan. I always will be.”</p>
                <cite>Omid Abtahi</cite>
              </blockquote>

              <p>Arriving on set the first day, the Cal State Fullerton alumnus was greeted by stormtroopers casually strolling down hallways, the sound of galactic blasters (“Pew! Pew!”) and twinkling spaceship lights. </p>

              <p>Abtahi, who plays Imperial scientist, Dr. Penn Pershing, in all three seasons of Disney+’s live-action “Star Wars” series, owes much of his success to his education.</p>

              <p>“The training I got at Cal State Fullerton, compared to what I've seen out in the real world, is top notch,” says Abtahi. “I’m very proud to be a Titan. I always will be.”</p>
            </div>
          </div>
          <div className='story-middle'>
            <div className='wrap small'>
              <figure className='align-wide' style={{ margin: 0 }}>
                <img src={OmidAsPershing} alt="Omid Abtahi as Dr. Penn Pershing" />
                <figcaption>Omid Abtahi as Dr. Penn Pershing. Courtesy of Lucasfilm.</figcaption>
              </figure>
            </div>
          </div>
          <div className='story-end'>
            <div className='wrap small'>

              <h2>Discovering the Magic of Theatre</h2>

              <p>Born in Iran, Abtahi ’02 (B.A. communications-advertising) moved to the United States when he was 5 years old. </p>

              <p>Other than performing in one high school play, Abtahi’s acting resume was almost nonexistent when he decided to attend Cal State Fullerton to pursue a career in advertising. </p>

              <p>After taking an acting course for nonmajors, he realized that his true passion was performing. </p>

              <figure className='align-right align-outside large'>
                <img src={OmidAbtahi} alt="Omid Abtahi" />
                <figcaption>Omid Abtahi ’02 (B.A. communications-advertising)</figcaption>
              </figure>

              <p>“I walked into the theatre department, and within those halls, it was all magic,” Abtahi says. “That was the first time in my life that I felt like I belonged somewhere.”</p>

              <p>Faculty members such as Jim Taulli ’94 (M.F.A. theatre arts-directing), professor emeritus of theatre and dance, encouraged him to pursue every creative opportunity.</p>

              <p>“His work was so rich and deep — far beyond what most actors in their early 20s achieve,” says Taulli. “It was particularly special to see him on ‘The Mandalorian’ doing fun yet quite touching character work.”</p>

              <p>Abtahi was inspired by the professional accomplishments of Professor of Theatre and Dance <a href="https://news.fullerton.edu/2022/11/theater-professor-lands-role-in-space-drama-series/" target='_blank' rel='noreferrer'>Svetlana Efremova-Reed</a>, who has over 20 years of acting experience in such titles as Apple TV+’s “For All Mankind,” Netflix’s original series, “Spinning Out” and “NCIS: Los Angeles.” </p>

              <blockquote className='align-left align-outside'>
                <p>“I’m grateful to my fellow students and my professors for some of the best years of my life.”</p>
                <cite>Omid Abtahi</cite>
              </blockquote>

              <p>“She taught me how to use the classics and incorporate them in a contemporary way, which was training that I called upon heavily when I was filming ‘The Mandalorian,’” says Abtahi. “She leads by example. When you see someone in person, and then you see them on screen, it makes it seem possible to have a successful acting career.”</p>

              <p>As a student, Abtahi participated in regional and national acting competitions, worked with professional actors at the Sundance Theatre Lab and interned with casting director Mali Finn, who was known for casting such films as “Titanic,” “LA Confidential” and “Avatar.” </p>

              <p>“It was through a connection at Cal State Fullerton that I got to work in Mali Finn's office, and I learned so much,” says Abtahi. “That's how I got my agent and how I got one of my first jobs on camera.”</p>

              <p>From booking his first role to acting in larger projects like “The Hunger Games: Mockingjay – Part 2” and the Apple TV+ series, “City on Fire,” Abtahi says he’s continued to rely on the training he received as a student in the <a href="https://www.fullerton.edu/arts/" target='_blank' rel='noreferrer'>College of the Arts</a>. </p>

              <p>“I've never taken an acting class outside of college. My process and skill set was built there,” says Abtahi. </p>

              <p>Even more than his training, Abtahi is grateful for the community of friends and faculty who continue to inspire him every day. </p>

              <p>“I met some of the best friends I've ever made in CSUF's theatre department. I’m grateful to my fellow students and my professors for some of the best years of my life.” </p>

            </div>
          </div>

          <Parallax
          bgImage={PershingHologram}
          bgImageAlt=""
          strength={300}
          style={{margin: 0}}
          className='full-width'>
            <div className="caption">
            Courtesy of Lucasfilm.
            </div>
            <div className='height' />
          </Parallax>

          <Parallax
          bgImage={PershingWithDroid}
          bgImageAlt=""
          strength={300}
          style={{margin: 0}}
          className='full-width'>
            <div className="caption">
            Courtesy of Lucasfilm.
            </div>
            <div className='height' />
          </Parallax>

          <div className='wrap small' style={{marginTop: '2em'}}>

            <h2>Jumping Into Hyperspace</h2>

            <p>“The Mandalorian'' premiered on Disney’s streaming platform in 2019. The show follows an independent bounty hunter named Din Djarin as he travels the outer rims of the galaxy and develops a father-son bond with a young, Yoda-like creature named Grogu.</p>

            <p>Grogu’s Jedi training and ability to use the Force make him a highly coveted target for the Empire. Abtahi’s character, Pershing, is intent on understanding the source of Grogu’s powers, but the ambiguity of his actions makes it hard to decide where his allegiance truly lies.</p>

            <p>“I fell in love with that struggle between good and evil in the films. Dr. Pershing lives in that morally gray area,” says Abtahi. </p>

            <p>In season three, Pershing was given an entire episode where fans break away from the Mandalorian’s storyline to learn more about the scientist’s background, motivations and possible future. </p>

            <p>Although initially nervous about focusing an episode on his character, Abtahi says that it was an incredible opportunity to bring depth to Pershing’s storyline, and the fan responses have been overwhelmingly positive. </p>

            <p>“It was the first time I was given that kind of spotlight in my career for a TV show. I got to work with such a high level of talent and technology, including droids and other creatures,” recalls Abtahi. “It was one of my top three experiences as an actor.” </p>

            <p>Throughout his time on the show, Abtahi has worked alongside a star-studded cast, including Pedro Pascal, Katee Sackhoff and Katy M. O'Brian. However, he says one of his most memorable co-star experiences happened with Grogu, the fan-favorite child. </p>

            <p>“It was my first day on set and they handed me this little green creature,” recalls Abtahi. “I just remember thinking, ‘He’s so adorable.’ It’s been amazing to see people connect with him throughout the show’s run.”</p>

            <p>From watching all the movies and spin off to interacting with excited fans, Abtahi says he’s been completely immersed in the wonder of the galactic world. </p>

            <p>“One of the best things about being in ‘Star Wars’ is that when people run into you, they light up,” says Abtahi. “The fact that I can make people smile and make them happy is such an awesome gift.” <span className='end-mark' /> </p>

              
          </div>
          
        </article>
        <div className='cta-banner' style={{ backgroundImage: `url(${ctaBanner})` }}>
          <div className='cta-text'>
            <p>
              <span className='cta-title'>Support the Department of Theatre and Dance</span><br />
              <a href="https://give.fullerton.edu/theatredance" target='_blank' rel='noreferrer'>give.fullerton.edu/theatredance</a>
            </p> 
          </div>
        </div>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 